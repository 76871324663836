<template>
  <main class="about">
    <Breadcrumb></Breadcrumb>

    <section class="">
      <PageHeader
        title="Serving the State of Georgia"
        :video="require(`@/assets/video/rural-ga.mp4`)"
        :backgroundImage="require(`@/assets/img/rural-ga-poster.jpg`)"
        backgroundColor="transparent"
        textColor="white"
        overlay="light"
        align="center"
        size="lg"
      >
        <p>
          As the state’s land-grant and sea-grant institution, UGA’s impact can
          be felt in all 159 counties in Georgia. The University of Georgia is
          driving economic development, creating innovative technology,
          preparing leaders, and helping entrepreneurs and small businesses
          thrive.
        </p>
      </PageHeader>
    </section>

    <!-- <section class="my-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10 col-xl-9 text-center">
            <h2>Impacting Georgia’s Economy</h2>
            <p>
              We are strengthening the Georgia economy with research,
              collaboration, and action. UGA’s impact in Georgia, a hub for
              international commerce, benefits communities here and around the
              world.
            </p>

            <p>
              As Georgia’s flagship institution, we are committed to the
              economic vitality of our state. UGA grows Georgia businesses by
              providing job opportunities, a vast talent pipeline, leadership
              development tools, and more.
            </p>
          </div>
        </div>
        <div id="impact-map" class="my-4">
          <div class="form-floating" style="max-width: 350px; margin: 0 auto;">
            <select
              id="map-selector"
              class="form-select"
              v-model="selectedMap"
              @change="changeMap($event)"
            >
              <option
                v-for="item in mapOptions"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <label for="map-selector">View our impact by</label>
          </div>
          <div
            id="sub-map"
            class="form-floating d-none"
            style="max-width: 350px; margin: 10px auto 0;"
          >
            <select
              id="sub-map-selector"
              class="form-select"
              v-model="selectedRegion"
              @change="getItemData(selectedRegion)"
            >
              <option
                v-for="item in allSelectedRegions"
                :key="item.id"
                :value="item.id"
              >
                {{ item.label }}
              </option>
            </select>
            <label for="map-selector">District</label>
          </div>

          <div
            class="map row align-items-start justify-content-center pt-4 mt-3 gx-5"
          >
            <div class="col-12 col-lg-6 col-xl-5">
              <svg
                class="map__svg"
                id="economic-map"
                ref="map"
                viewBox="0 0 400 480"
              >

              </svg>
            </div>
            <div class="col-12 col-lg-6 col-xl-5 map__data">
              <h3 id="district-header">
                <span id="district-prefix"></span>
                <span id="district">The State of Georgia</span>
                <span id="district-suffix"></span>
              </h3>
              <div class="row">
                <ul id="keydata" class="col-12 map__keydata list-unstyled">
                  <li class="map__keydata__point map__keydata__point--lg">
                    <span id="impact" class="map__keydata__stat fw-bold"
                      >$8.1 billion</span
                    >
                    <span id="impact-label" class="map__keydata__label">
                      annual economic impact (FY23)</span
                    >
                  </li>
                  <li class="map__keydata__point">
                    <span id="alumni" class="map__keydata__stat fw-bold"
                      >226,158</span
                    >
                    <span id="alumni-label" class="map__keydata__label">
                      UGA alumni in Georgia</span
                    >
                  </li>
                  <li id="employees" class="map__keydata__point">
                    <span id="people" class="map__keydata__stat fw-bold"
                      >1.6 million</span
                    >
                    <span id="people-label" class="map__keydata__label">
                      personal contacts made by UGA Extension in FY22</span
                    >
                  </li>
                  <li class="map__keydata__point">
                    <span id="students" class="map__keydata__stat fw-bold"
                      >34,265</span
                    >
                    <span id="students-label" class="map__keydata__label">
                      Georgia students enrolled in 2022–2023</span
                    >
                  </li>
                </ul>
                <ul class="col-12 col-lg-4 map__metadata list-unstyled"></ul>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </section> -->

    <section>
      <Section
        class=""
        type="inset"
        imgSide="left"
        imgURL="serving-georgia/farmer-in-field.jpg"
      >
        <h3>Strengthening Our Communities</h3>
        <p>
          The University of Georgia is driven to help families, farms,
          communities, and economies across the state. We are strengthening
          workforce development, community health, and civic engagement in order
          to create a more prosperous Georgia.
        </p>

        <p>
          With hundreds of faculty members dedicated specifically to serving
          Georgia, UGA is working to help improve health care, infrastructure,
          and local economies in Georgia’s rural communities.
        </p>

        <p>
          Our commitment to the state and its residents reaches all of Georgia,
          from the smallest towns to the largest urban centers.
        </p>
      </Section>
      <!-- <Section
        type="standard"
        imgSide="left"
        imgURL="serving-georgia/farmer-in-field.jpg"
        color="bg-dark bg-diagonal--dark"
        class="text-white"
      >
        <h3>Strengthening Our Communities</h3>
        <p>The University of Georgia is driven to help families, farms, communities, and economies across the state. We are strengthening workforce development, community health, and civic engagement in order to create a more prosperous Georgia.</p>

        <p>With hundreds of faculty members dedicated specifically to serving Georgia, UGA is working to help improve health care, infrastructure, and local economies in Georgia’s rural communities.</p>

        <p>Our commitment to the state and its residents reaches all of Georgia, from the smallest towns to the largest urban centers.</p>
      </Section> -->
      <div class="row g-0">
        <div
          class="col-md-4 bg-red text-white d-flex flex-column justify-content-center"
        >
          <div class="text-center p-5">
            UGA contributed
            <span class="d-block display-6 fw-bold">$216.72 million</span> to
            the state’s agriculture economy. (FY21)
          </div>
        </div>
        <div
          class="col-md-4 bg-black text-white d-flex flex-column justify-content-center"
        >
          <div class="text-center p-5">
            Every Georgian is within
            <span class="d-block display-6 fw-bold">40 miles</span> of a UGA
            facility.
          </div>
        </div>
        <div
          class="col-md-4 bg-light text-black d-flex flex-column justify-content-center"
        >
          <div class="text-center p-5">
            <span class="d-block display-6 fw-bold">30,000</span> programs
            offered through UGA Cooperative Extension
          </div>
        </div>
      </div>
    </section>

    <section>
      <Section
        class=""
        type="inset"
        imgSide="right"
        imgURL="serving-georgia/arthur-blank-students.jpg"
      >
        <h3>Education</h3>
        <p>
          We are empowering Georgia citizens through education and training. The
          University of Georgia provides a top-tier education to our students.
          But that’s not all. Our youth development, leadership training, and
          continuing education programs ensure that everyone in Georgia has
          access to the knowledge and resources necessary thrive in today’s
          economy.
        </p>
      </Section>
      <!-- <Section
        type="standard"
        imgSide="left"
        imgURL="serving-georgia/arthur-blank-students.jpg"
        color="bg-light bg-diagonal--light"
        class="text-black"
      >
        <h3>Education</h3>
        <p>We are empowering Georgia citizens through education and training. The University of Georgia provides a top-tier education to our students. But that’s not all. Our youth development, leadership training, and continuing education programs ensure that everyone in Georgia has access to the knowledge and resources necessary thrive in today’s economy.</p>
      </Section> -->
      <div class="row g-0">
        <div
          class="col-md-4 bg-red text-white d-flex flex-column justify-content-center"
        >
          <div class="text-center p-5">
            <span class="d-block display-6 fw-bold">242,000</span> children
            participate in 4-H programs in a typical year
          </div>
        </div>
        <div
          class="col-md-4 bg-black text-white d-flex flex-column justify-content-center"
        >
          <div class="text-center p-5">
            <span class="d-block display-6 fw-bold">33,710</span> Georgia
            students enrolled in 2021–2022
          </div>
        </div>
        <div
          class="col-md-4 bg-light text-black d-flex flex-column justify-content-center"
        >
          <div class="text-center p-5">
            <span class="d-block display-6 fw-bold">300+</span> continuing
            education programs (FY21)
          </div>
        </div>
      </div>
    </section>

    <section>
      <Section
        class=""
        type="inset"
        imgSide="left"
        imgURL="serving-georgia/water-sampling.jpg"
      >
        <h3>Research &amp; Discovery</h3>
        <p>
          We are discovering new ways to keep Georgia at the forefront of
          agricultural technology, engineering, sustainability, and more.
        </p>

        <p>
          As the state’s most comprehensive research university, our discoveries
          accelerate Georgia’s technology development and research capabilities.
          Our Innovation District moves new ideas and technologies to the public
          sphere, encourages startups and small businesses, and promotes
          entrepreneurship to drive economic development in our state and
          beyond.
        </p>
      </Section>
      <!-- <div class="row g-0">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="d-flex flex-column justify-content-center h-100 p-5">
            <h2>Research &amp; Discovery</h2>
            <p>We are discovering new ways to keep Georgia at the forefront of agricultural technology, engineering, sustainability, and more.</p>

            <p>As the state’s most comprehensive research university, our discoveries accelerate Georgia’s technology development and research capabilities. Our Innovation District moves new ideas and technologies to the public sphere, encourages startups and small businesses, and promotes entrepreneurship to drive economic development in our state and beyond.</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-8" style="min-height: 400px;">
          <VideoBackground
            :src="require('@/assets/video/researchers.mp4')"
            :poster="require('@/assets/img/research/marshall-shepherd.jpg')"
          />
        </div>
      </div> -->
      <div class="row g-0">
        <div
          class="col-12 col-sm-6 col-lg-3 bg-red text-white d-flex flex-column justify-content-center"
        >
          <div class="text-center p-5">
            <span class="d-block display-6 fw-bold">900+</span> products derived
            from university research (FY21)
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-lg-3 bg-light text-black d-flex flex-column justify-content-center"
        >
          <div class="text-center p-5">
            <span class="d-block display-6 fw-bold">$494M</span> in R&D
            expenditures (FY21)
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-lg-3 bg-black text-white d-flex flex-column justify-content-center"
        >
          <div class="text-center p-5">
            <span class="d-block display-6 fw-bold">+41%</span> overall R&D
            expenditures since FY2013
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-lg-3 bg-light text-black d-flex flex-column justify-content-center"
        >
          <div class="text-center p-5">
            <span class="d-block display-6 fw-bold">200+</span> companies
            launched based on UGA research (FY21)
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
// import EmergencyBanner from '@/components/EmergencyBanner.vue';
// import Btn from '@/components/Btn.vue';
import Breadcrumb from "@/components/Breadcrumb.vue";
import PageHeader from "@/components/PageHeader.vue";
// import Figure from '@/components/Figure.vue';
import Section from "@/components/Section.vue";
// import VideoBackground from '@/components/VideoBackground.vue'

// import NiceSelect from "nice-select2";
import Snap from "snapsvg-cjs"; // Docs: https://www.npmjs.com/package/snapsvg-cjs

export default {
  name: "Serving-Georgia",
  metaInfo: {
    title: "Serving the State of Georgia",
    description: "",
    og: {
      image: "",
      title: "",
      description: "",
    },
  },
  data() {
    return {
      mapSVG: undefined,
      gray: "#9EA2A2",
      red: "#BA0C2F",
      selectedMap: undefined,
      selectedRegion: undefined,
      allSelectedRegions: undefined,
      mapOptions: [
        {
          id: "state",
          name: "State",
          placeholder: "",
          items: [],
        },
        {
          id: "region",
          name: "Service Delivery Regions",
          placeholder: "Region",
          items: [],
        },
        {
          id: "county",
          name: "Counties",
          placeholder: "District",
          items: [],
        },
        {
          id: "congress",
          name: "U.S. Congressional Districts",
          placeholder: "District",
          items: [],
        },
        {
          id: "senate",
          name: "State Senate Districts",
          placeholder: "District",
          items: [],
        },
        {
          id: "house",
          name: "State House Districts",
          placeholder: "District",
          items: [],
        },
      ],
    };
  },
  computed: {
    // Returns 'state'
    defaultMap() {
      return this.mapOptions[0].id;
    },
  },
  methods: {
    // Fetches json data for each map-type and fills the items array with it
    async getMapData(mapName) {
      // if (this.mapOptions.find(element => element.id == mapName).items.length == 0) :
      // this.axios
      //   // get the JSON file that matches the id of this item
      //   .get("/map-data/" + mapName + ".json")
      //   .then((response) => {
      //     // replace the 'items' array in this object with the data from the JSON file
      //     this.mapOptions.find(element => element.id == mapName).items = response.data;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     // this.errored = true;
      //   })
      //   .finally(() => {
      //     this.loading = false}
      //   );
      // endif;
      // for each type
      this.mapOptions.forEach((item, index) => {
        this.axios
          // get the JSON file that matches the id of this item
          .get("/map-data/" + item.id + ".json")
          .then((response) => {
            // replace the 'items' array in this object with the data from the JSON file
            this.mapOptions[index].items = response.data;
          })
          .catch((error) => {
            console.log(error);
            // this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // Updates the svg file when user chooses a different option
    changeMap() {
      // hide all maps
      this.mapSVG.selectAll(".map-type").forEach(function(element) {
        element.attr({ display: "none" });
      });

      // show this map
      this.mapSVG.select("#" + this.selectedMap).attr({ display: "" });

      // sets regions related to selected map
      this.allSelectedRegions = this.mapOptions[
        this.mapOptions.findIndex((maps) => maps.id === this.selectedMap)
      ].items;

      // When map changes, select the first district and update info
      if (this.selectedMap === "state") {
        document.getElementById("sub-map").classList.add("d-none");
        this.updateMapData(this.mapOptions[0].items[0]);
      } else {
        document.getElementById("sub-map").classList.remove("d-none");
        this.selectedRegion = this.allSelectedRegions[0].id;
        this.updateMapData(
          this.mapOptions[
            this.mapOptions.findIndex((maps) => maps.id === this.selectedMap)
          ].items[0]
        );
      }

      // update the labels for the stats next to map
      this.updateMapLabels();
    },
    updateMapLabels() {
      const districtPrefix = document.getElementById("district-prefix");
      const districtSuffix = document.getElementById("district-suffix");
      const alumniLabel = document.getElementById("alumni-label");
      const studentsLabel = document.getElementById("students-label");
      const peopleLabel = document.getElementById("people-label");
      const impactLabel = document.getElementById("impact-label");
      const employeesEl = document.getElementById("employees");

      // Label handlers for the selected region header
      districtPrefix.textContent = "";
      districtSuffix.textContent = "";

      switch (this.selectedMap) {
        case "state":
          districtPrefix.textContent = "The State of Georgia";
          break;
        case "region":
          districtPrefix.textContent = "Service Delivery ";
          break;
        case "county":
          districtSuffix.textContent = " County";
          break;
        case "congress":
          districtSuffix.textContent = " Congressional District";
          break;
        case "senate":
          districtPrefix.textContent = "Georgia Senate ";
          break;
        case "house":
          districtPrefix.textContent = "Georgia House ";
          break;
      }

      // Label handlers for data
      if (this.selectedMap === "state") {
        employeesEl.style.display = "block";

        impactLabel.textContent = " annual economic impact (FY22)";
        alumniLabel.textContent = " UGA alumni in Georgia";
        peopleLabel.textContent =
          " personal contact made by UGA Extensions in FY22";
        studentsLabel.textContent = " Georgia students enrolled in 2022-2023";
      } else {
        impactLabel.textContent = " alumni economic impact";
        alumniLabel.textContent = " UGA alumni";
        studentsLabel.textContent = " students";

        if (
          this.selectedMap === "senate" ||
          this.selectedMap === "house" ||
          this.selectedMap === "region" ||
          this.selectedMap === "county"
        ) {
          employeesEl.style.display = "block";

          peopleLabel.textContent = " faculty & staff";
        } else {
          //congress, county
          employeesEl.style.display = "none";
        }
      }
    },
    getMapItems() {
      this.mapOptions.forEach((map) => {
        if (map.id === this.selectedMap) {
          return map.items;
        }
      });
    },
    showDefault() {
      console.log(this.mapSVG.select);
      this.mapSVG.select("#" + this.defaultMap).attr({ display: "" });
      // document.getElementById("sub-map").style.display = "none";
    },
    initMap() {
      this.mapSVG = window.Snap("#economic-map"); // Creates the parent svg HTML element

      Snap.load("/economic_map_2022.svg", this.onMapLoaded);
      // get the JSON data for the map
      this.getMapData();
    },
    onMapLoaded(data) {
      // data is the Fragment created by SnapSVG
      // maps is a list of the g tags that hold the svg info
      let maps = data.selectAll(
        "#house, #senate, #county, #congress, #region, #state"
      );

      // For each map element add this class and hide
      maps.forEach(function(element) {
        element.addClass("map-type");
        element.attr({ display: "none" });
      });

      // Adds all the g tags and associated path tags to the HTML
      this.mapSVG.add(maps);

      // set default map styles
      this.mapSVG.selectAll(".map-type path").attr({
        stroke: "#ffffff",
        strokeWidth: 1,
        fill: this.gray,
      });

      // // Add click event to all path elements
      this.mapSVG.selectAll("path").forEach((element) => {
        const pathId = element.node.id;

        element.click(() => {
          this.getItemData(pathId);
        });
      });

      // Displays the default map: 'State'
      this.showDefault();
    },
    getItemData(pathId) {
      // Get clicked item's keypoints and meta info
      for (const item of this.allSelectedRegions) {
        if (item.id === pathId) {
          this.selectedRegion = pathId;
          this.updateMapData(item);
        }
      }
    },
    updateMapData(item) {
      // Remove "active" class from all path elements
      const activeDistrict = this.mapSVG.selectAll(".map-type path.active");
      if (activeDistrict.length)
        activeDistrict.forEach((el) => {
          el.removeClass("active");
        });

      // Add "active" class to selectedRegion
      this.mapSVG.select("#" + this.selectedRegion).addClass("active");

      // Update name and main data points for selectedRegion
      document.getElementById("district").textContent = item.label;
      document.getElementById("impact").textContent = item.keypoints.impact;
      document.getElementById("alumni").textContent = item.keypoints.alumni;
      document.getElementById("people").textContent = item.keypoints.people;
      document.getElementById("students").textContent = item.keypoints.students;

      // Empty the meta list if one already exists
      const metaList = document.querySelector(".map__metadata");
      while (metaList.firstChild) {
        metaList.removeChild(metaList.lastChild);
      }

      // If the newly selected region has metadata then loop through data points and create HTML elements for each
      const keydataList = document.getElementById("keydata");
      if (item.meta) {
        // Splits data columns
        keydataList.classList.add("col-lg-8");

        item.meta.forEach((dataPoint, idx) => {
          // Conditional to omit last dataPoint for "county" instead of deleting them from the JSON file manually because this is easier.
          if (item.type === "county" && idx === item.meta.length - 1) {
            return;
            // If the dataPoint contains all necessary info this creates HTML and adds it to the list
          } else if (dataPoint.value && dataPoint.value != "") {
            const listEl = document.createElement("li");
            const statEl = document.createElement("span");
            const labelEl = document.createElement("span");
            listEl.setAttribute("class", "map__metadata__point");
            statEl.setAttribute("class", "map__metadata__stat fw-bold");
            labelEl.setAttribute("class", "map__metadata__label");
            statEl.textContent = dataPoint.value + " ";
            labelEl.textContent = dataPoint.text;

            if (dataPoint.url && dataPoint.url != null) {
              statEl.innerHTML =
                "<a target='_blank' href='" +
                dataPoint.url +
                "'>" +
                dataPoint.value +
                " </a>";
            }

            listEl.appendChild(statEl);
            listEl.appendChild(labelEl);
            metaList.appendChild(listEl);
          }
        });
      } else {
        keydataList.classList.remove("col-lg-8"); // If no meta data, key data takes up entire width of container
      }

      // Google Tag Manager
      if (this.$gtm.enabled()) {
        window.dataLayer?.push({
          event: "Map Filter",
          mapRegion: this.selectedRegion,
          mapAction: "map",
        });
      }
    },
  },
  mounted() {
    this.initMap();
    this.selectedMap = this.defaultMap;
  },
  components: {
    // EmergencyBanner,
    // Btn,
    PageHeader,
    Breadcrumb,
    // Figure,
    Section,
    // VideoBackground,
  },
};
</script>

<style lang="scss">
.map__svg {
  width: 100%;
  height: 460px;
  // width: 385px;
  // height: 460px;
  // overflow: scroll;
  path {
    cursor: pointer;
    fill: $gray-800;
    &:hover,
    &.active {
      fill: $red;
    }
    &#state {
      fill: $red;
    }
  }
}
.list-unstyled {
  margin: 0;
}
.map__keydata {
  &__point {
    font-size: 1.25rem;
    padding: 1rem 0;
    line-height: 1.25;
    // &:not(:first-child) {
    border-bottom: solid 1px $gray-200;
    // }
    &--lg {
      font-size: 1.5rem;
    }
  }
}
.map__metadata {
  &__point {
    font-size: 1rem;
    padding: 1rem 0;
    line-height: 1.25;
  }
  &__label {
    display: block;
    padding-top: 0.5rem;
    font-size: 0.8rem;
    opacity: 0.5;
  }
  &__stat a {
    color: inherit;
  }
}

#district-header {
  font-family: "Oswald", "Impact", "Arial Narrow", "Helvetica Neue";
  font-weight: 400;
  text-transform: uppercase;
  font-size: 2.6rem;
  margin-bottom: 2rem;
}
</style>
