<template>
  <div class="figure" :class="computedClass">
    <div class="figure__container">
      <span v-if="heading" class="figure__copy" v-html="heading"></span>
      <Vue3AutoCounter
        class="figure__stat"
        ref="counter"
        :startAmount="startAmount"
        :endAmount="endAmount"
        :duration="duration"
        :prefix="prefix"
        :suffix="suffix"
        :separator="separator"
        decimalSeparator="."
        :decimals="decimals"
        :autoinit="autoinit"
        :data-aos="animation"
      />
      <span class="figure__copy" v-html="subheading"></span>
      <span v-if="citation" class="figure__citation" v-html="citation"></span>
    </div>
  </div>
</template>

<script>
import Vue3AutoCounter from "vue3-autocounter";
export default {
  name: "Figure",
  props: {
    prefix: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    separator: {
      type: String,
      default: "",
    },
    duration: {
      type: Number,
      default: 3,
    },
    decimals: {
      type: Number,
      default: 0,
    },
    autoinit: {
      type: Boolean,
      default: true,
    },
    startAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    endAmount: {
      type: Number,
      required: true,
    },
    heading: {
      type: String,
      required: false
    },
    subheading: { 
      type: String, 
      required: true 
    },
    citation: { 
      type: String, 
      required: false
    },
    /**
     * Background color for Figure
     * Text color will be determined based on this
     */
    backgroundColor: {
      type: String,
      required: true,
      default: "white",
    },
    textColor: {
      type: String,
      required: true,
      default: "black",
    },
    textured: {
      type: Boolean,
      default: false,
    },
    animation: {
      type: String,
      required: false,
      default: "fade-up",
    },
  },
  components: {
    Vue3AutoCounter,
  },
  computed: {
    /**
     * Adds color prop to classes for Figure
     */
    computedClass() {
      let cls = [];

      let backgroundColor = this.backgroundColor.toLowerCase();
      if (this.textured) cls.push(`bg-diagonal--dark`);
      cls.push(`bg-${backgroundColor}`);
      cls.push(`text-${this.textColor}`);

      return cls;
    },
  },
  // created() {
  //   document.addEventListener("aos:in", ({ detail }) => {
  //     // start the counter
  //     // console.log(this);
  //     // start();
  //     console.log(detail);
  //   });
  //   document.addEventListener("aos:out", ({ detail }) => {
  //     console.log("animated out", detail);
  //   });
  // },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.figure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1.5rem;
  height: 100%;
  line-height: 1.25;

  &__container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__stat {
    @include heading-base;
    font-weight: 800;
    margin-bottom: 1rem;
    font-size: 5rem;
  }
  &__copy {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bolder;
    text-align: center;
    font-size: 1.5rem;
  }
  &__citation {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    text-align: center;
    font-size: 1rem;
    font-style: italic;
    margin-top: 5px;
  }
}
@include media-breakpoint-up(md) {
  .figure {
    min-height: 185px;
    &__copy {
      // font-size: 1.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .figure {
    aspect-ratio: 1 / 1;

    @supports not (aspect-ratio: 1 / 1) {
      padding: 0;
      width: 100%;
      padding-top: 100%;
      position: relative;
      &__container {
        position: absolute;
        width: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .figure {
    &__stat {
      font-size: 8rem;
    }
    &__copy {
      font-size: 2rem;
    }
    &__citation {
      font-size: 1.4rem;
    }
  }
}
</style>
