<template>
  <div class="quickfact">
    <img :src="require(`@/assets/img/${imageURL}`)" class="quickfact__image" loading="lazy" />
    <h5 class="quickfact__heading">{{ heading }}</h5>
    <div class="quickfact__text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuickFact',
  props: {
    imageURL: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.quickfact {
  font-family: $font-family-serif;
  font-weight: $font-weight-normal;
  &__image {
    margin-bottom: 2rem;
  }
  &__heading {
    font-size: 1.0625rem;
    font-weight: $font-weight-bold;
    margin-bottom: 0.75rem;
  }
  &__text {
    font-family: $font-family-serif;
    font-size: 0.8125rem;
  }
}
@include media-breakpoint-up(lg) {
  .quickfact {
    &__heading {
      font-size: 2rem;
    }
    &__text {
      font-size: 1.1875rem;
    }
  }
}
</style>
